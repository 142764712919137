import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function Biological() { 
	return( 
	<>  
    
      <Header /> 
       
      <main> 
      <section class="innerslider no-shadow"> 
            <div class="container">
                <div class="row">
                    <div class="col-md-12"> 	
                        <div class="contants">
                            <h1>Facilities</h1>
                            <div class="page-title-inner"> 
                                <ol class="breadcrumb mb-0 justify-content-center">
                                    <li class="breadcrumb-item"><a href="/" title="">Home</a></li>
                                    <li class="breadcrumb-item active">Library</li>
                                </ol>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="sidebar ttm-sidebar pt-20 pb-20">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 widget-area" id="bar-fixed">
                        <aside class="widget widget-nav-menu">
                            <ul class="widget-menu">
                                <li><a href="/Facilities">Library </a></li>
                                <li><a href="/Computer">Computer Lab</a></li>
                                <li><a href="/Educational">Educational Technology Lab</a></li>
                                <li><a href="/Psychology">Psychology Lab</a></li>
                                <li><a class="active" href="/Biological">Biological Science Lab</a></li>
                                <li><a href="/Language">Language Lab</a></li>
                            </ul>
                        </aside>                            
                        <aside class="widget widget-download">
                            <h3 class="widget-title">Download</h3>
                            <ul class="download">
                                <li>
                                    <i class="fa fa-file-pdf-o"></i>
                                    <a href="../assets/images/PSM_College_Application.pdf" title="Download">Application</a>
                                </li> 
                            </ul>
                        </aside>
                    </div>
                    <div class="col-lg-9 content-area" id="Library"> 
                        <div class="ttm-service-single-content-area"> 
                            <div class="section-title with-desc text-left clearfix">
                                <div class="title-header"> 
                                    <h2 class="title">Biological Science Laboratory</h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 ttm_single_image-wrapper mb-35">
                                    <img class="img-fluid" src="../assets/images/biological.jpg" alt="single-img-twelve"/>
                                </div>
                                <div class="col-md-12 ttm-service-description"> 
                                    <p>Bio lab helps in providing the general knowledge of the basic principles of biological systems through a series of required practices and develop skills in the use of current methodology and investigation through laboratory courses</p>
                                </div>
                            </div>  
                        </div> 
                    </div>                    
                </div>
            </div>
        </section> 
        <section class="our-product mt-40">
            <div class="container">
                <div class="row">
                    <div class="col-md-7">
                        <div class="sec-2-contant">
                            <h4>The Truly Global College</h4>
                            <h2>Contact Us to schedule a call with an Admission Officer</h2>
                            <p>For more information or for questions,<br/>
                            <strong>stpaulsb.edcollege@gmail.com </strong> <br/>
                            <strong>+91-98427 05888.</strong>
                            </p>
                            <a class="thm-btn v2 thm-bg brd-rd5 d-inline-block position-relative overflow-hidden" href="/Contact">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main>

      <Footer/>

  </> 
  ); 
  } 
  export default Biological;