import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function Gallery() { 
	return( 
	<>  
    
      <Header /> 

        <main> 
            <section class="innerslider no-shadow"> 
                <div class="container">
                    <div class="row">
                        <div class="col-md-12"> 	
                            <div class="contants">
                                <h1>Gallery</h1>
                                <div class="page-title-inner"> 
                                    <ol class="breadcrumb mb-0 justify-content-center">
                                        <li class="breadcrumb-item"><a href="/" title="">Home</a></li>
                                        <li class="breadcrumb-item active">Gallery</li>
                                    </ol>
                                </div>                            
                            </div>
                        </div>
                    </div>
                </div>
            </section>  
             
            <section>
                <div class="w-100 pt-110 pb-110 position-relative">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-7 col-md-8">
                                <div class="section-title text-center mb-50">
                                    <h2 class="title title-1">Our Gallery</h2>
                                    <p>Here is what you can expect from a house cleaning from a Handy professional. Download the app
                                        to share further cleaning details and instructions!</p>
                                </div>
                            </div>
                        </div> 
                        <div class="gallery-wrap v3 text-center position-relative w-100">
                            <div class="row mrg30">                                
                                <div class="col-md-6 col-sm-12 col-lg-3">
                                    <div class="gallery-box v3 brd-rd10 position-relative overflow-hidden w-100">
                                        <img class="img-fluid w-100" src="assets/images/gallery/1.jpg" alt="Gallery Image 3"/>
                                        <div class="gallery-info position-absolute">
                                            <a class="d-inline-block" href="assets/images/gallery/1-1.jpeg" data-fancybox="gallery" title=""><i class="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 col-lg-3">
                                    <div class="gallery-box v3 brd-rd10 position-relative overflow-hidden w-100">
                                        <img class="img-fluid w-100" src="assets/images/gallery/2.jpg" alt="Gallery Image 4"/>
                                        <div class="gallery-info position-absolute">
                                            <a class="d-inline-block" href="assets/images/gallery/2-1.jpeg" data-fancybox="gallery" title=""><i class="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-lg-3">
                                    <div class="gallery-box v3 brd-rd10 position-relative overflow-hidden w-100">
                                        <img class="img-fluid w-100" src="assets/images/gallery/3.jpg" alt="Gallery Image 5"/>
                                        <div class="gallery-info position-absolute">
                                            <a class="d-inline-block" href="assets/images/gallery/3-1.jpeg" data-fancybox="gallery" title=""><i class="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-lg-3">
                                    <div class="gallery-box v3 brd-rd10 position-relative overflow-hidden w-100">
                                        <img class="img-fluid w-100" src="assets/images/gallery/4.jpg" alt="Gallery Image 5"/>
                                        <div class="gallery-info position-absolute">
                                            <a class="d-inline-block" href="assets/images/gallery/4-1.jpeg" data-fancybox="gallery" title=""><i class="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 col-lg-3">
                                    <div class="gallery-box v3 brd-rd10 position-relative overflow-hidden w-100">
                                        <img class="img-fluid w-100" src="assets/images/gallery/5.jpg" alt="Gallery Image 3"/>
                                        <div class="gallery-info position-absolute">
                                            <a class="d-inline-block" href="assets/images/gallery/5-1.jpeg" data-fancybox="gallery" title=""><i class="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 col-lg-3">
                                    <div class="gallery-box v3 brd-rd10 position-relative overflow-hidden w-100">
                                        <img class="img-fluid w-100" src="assets/images/gallery/6.jpg" alt="Gallery Image 4"/>
                                        <div class="gallery-info position-absolute">
                                            <a class="d-inline-block" href="assets/images/gallery/6-1.jpeg" data-fancybox="gallery" title=""><i class="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-lg-3">
                                    <div class="gallery-box v3 brd-rd10 position-relative overflow-hidden w-100">
                                        <img class="img-fluid w-100" src="assets/images/gallery/7.jpg" alt="Gallery Image 5"/>
                                        <div class="gallery-info position-absolute">
                                            <a class="d-inline-block" href="assets/images/gallery/7-1.jpg" data-fancybox="gallery" title=""><i class="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-lg-3">
                                    <div class="gallery-box v3 brd-rd10 position-relative overflow-hidden w-100">
                                        <img class="img-fluid w-100" src="assets/images/gallery/8.jpg" alt="Gallery Image 5"/>
                                        <div class="gallery-info position-absolute">
                                            <a class="d-inline-block" href="assets/images/gallery/8-1.jpg" data-fancybox="gallery" title=""><i class="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div> 
                    </div>
                </div>
            </section>
        </main>
      
      <Footer/>

  </> 
  ); 
  } 
  export default Gallery;