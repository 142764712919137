import React from 'react';

// import NCTE from '../../images/pdf/NCTE.pdf'; 

function  Header () {
  return (
  <>
  
  <header class="style1 w-100">
                <div class="topbar  w-100">
                    <div class="container">
                        <div class="topbar-inner w-100">
                            <ul class="top-links mb-0 list-unstyled d-inline-flex align-items-center flex-wrap">
                                {/* <li><a href="#" title="">Download Applications</a></li> */}
                                {/* <li><a title="">Approved by NCTE : F:SRO/NCTE/B.Ed/APSO4846/ 2006-2007 / 9716</a></li> */}
                            </ul>
                            <div class="topbar-right d-inline-flex align-items-center flex-wrap">
                                <div class="social-links d-inline-flex">
                                    {/* <span class="d-inline-block">Follow Us:</span> */}
                                    <a href="https://twitter.com/" title="Twtiiter" target="_blank"><i class="fab fa-twitter"></i></a>
                                    <a href="https://www.facebook.com/" title="Facebook" target="_blank"><i class="fab fa-facebook-f"></i></a>
                                    <a href="https://www.youtube.com/" title="Youtube" target="_blank"><i class="fab fa-youtube"></i></a>
                                    <a href="https://www.linkedin.com/" title="Linkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="logo-contact-menu-wrap position-relative w-100">
                    <div class="container">
                        <div class="logo-contact-inner d-flex flex-wrap align-items-center justify-content-between position-relative w-100">
                            <div class="logo z1 position-relative"><h1 class="mb-0"><a class="d-block" href="/" title="Home"><img class="img-fluid" src="assets/images/logo.png" alt="Logo" /></a></h1></div>
                            <div class="header-contact-cart d-flex flex-wrap align-items-center">
                                <ul class="header-contact-info d-inline-flex flex-wrap align-items-center mb-0 list-unstyled">
                                    <li>
                                        <i class="far fa-envelope thm-clr"></i>
                                        <span class="d-block scndry-clr">Email Id:</span>
                                        <strong class="d-block">stpaulsb.edcollege@gmail.com</strong>
                                    </li>
                                    <li>
                                        <i class="fas fa-phone-alt thm-clr"></i>
                                        <span class="d-block scndry-clr">Phone Number:</span>
                                        <strong class="d-block">+91-98427 05888</strong>
                                    </li>
                                </ul> 
                            </div>
                        </div>
                        
                    </div>
                <div class="header-menu-bg">
                    <div class="container">
                        <nav class="d-flex brd-rd5 flex-wrap align-items-center justify-content-between w-100">
                            <div class="header-left">
                                <ul class="mb-0 list-unstyled d-inline-flex">
                                    <li><a href="/">Home</a></li>                                    
                                    <li><a href="/AboutUs">About Us</a></li>
                                    <li><a href="/Course">Course</a></li>
                                    <li><a href="/Facilities">Facilities</a></li>
                                    <li><a href="/Gallery">Gallery</a></li>
                                    <li class="menu-item-has-children">
                                        <a href="javascript:void(0);" title="">Mandate</a>
                                        <ul class="mb-0 list-unstyled">                                            
                                            <li><a href="/assets/images/pdf/NCTE.pdf" target="_blank">NCTE Approval</a></li>
                                            <li><a href="/assets/images/pdf/affliation-order.pdf" target="_blank">TNTEU Affiliation</a></li>
                                            <li><a href="/assets/images/pdf/bed-syllabus.pdf" target="_blank">B.Ed. Syllabus</a></li>
                                            <li><a href="/assets/images/pdf/application.pdf" target="_blank">B.Ed. Admission Norms</a></li> 
                                        </ul>
                                    </li>
                                    <li><a href="/ContactUs">Contact Us</a></li>
                                </ul>
                            </div> 
                        </nav>
                    </div>
                    </div>
                </div>
            </header>
            <div class="header-search d-flex flex-wrap align-items-center position-fixed w-100">
                <span class="search-close-btn position-absolute"><i class="fas fa-times"></i></span> 
            </div>
            <div class="sticky-menu">
                <div class="container">
                    <div class="sticky-menu-inner d-flex flex-wrap align-items-center justify-content-between w-100">
                        <div class="logo"><h1 class="mb-0"><a class="d-block" href="/" title="Home"><img class="img-fluid" src="assets/images/logo.png" alt="Logo" /></a></h1></div>
                        <nav class="d-flex flex-wrap align-items-center justify-content-between">
                            <div class="header-left">
                                <ul class="mb-0 list-unstyled d-inline-flex">
                                    <li><a href="/" >Home</a></li>                                    
                                    <li><a href="/AboutUs">About Us</a></li>
                                    <li><a href="/Course">Course</a></li>
                                    <li><a href="/Facilities">Facilities</a></li>
                                    <li><a href="/Gallery">Gallery</a></li>
                                    <li class="menu-item-has-children">
                                        <a href="javascript:void(0);" title="">Mandate</a>
                                        <ul class="mb-0 list-unstyled">
                                            <li><a href="/assets/images/pdf/NCTE.pdf" target="_blank">NCTE Approval</a></li>
                                            <li><a href="/assets/images/pdf/affliation-order.pdf" target="_blank">TNTEU Affiliation</a></li>
                                            <li><a href="/assets/images/pdf/bed-syllabus.pdf" target="_blank">B.Ed. Syllabus</a></li>
                                            <li><a href="/assets/images/pdf/application.pdf" target="_blank">B.Ed. Admission Norms</a></li> 
                                        </ul>
                                    </li>
                                    <li><a href="/ContactUs">Contact Us</a></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="rspn-hdr">
                <div class="rspn-mdbr">
                    <div class="rspn-scil d-inline-flex flex-wrap">
                        <a class="twitter-hvr" href="https://twitter.com/" title="Twtiiter" target="_blank"><i class="fab fa-twitter"></i></a>
                        <a class="facebook-hvr" href="https://www.facebook.com/" title="Facebook" target="_blank"><i class="fab fa-facebook-f"></i></a>
                        <a class="youtube-hvr" href="https://www.youtube.com/" title="Youtube" target="_blank"><i class="fab fa-youtube"></i></a>
                        <a class="linkedin-hvr" href="https://www.linkedin.com/" title="Linkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                    </div> 
                </div>
                <div class="lg-mn">
                    <div class="logo"><h1 class="mb-0 d-block"><a href="/" title="Home"><img src="assets/images/logo.png" alt="Logo"/></a></h1></div>
                    <div class="rspn-cnt">
                        <span><i class="thm-clr far fa-envelope"></i><a href="mailto:info@youremailid.com" title="">info@youremailid.com</a></span>
                        <span><i class="thm-clr fas fa-phone-alt"></i>+96 125 554 24 5</span>
                    </div>
                    <span class="rspn-mnu-btn"><i class="fa fa-list-ul"></i></span>
                </div>
                <div class="rsnp-mnu">
                    <span class="rspn-mnu-cls"><i class="fa fa-times"></i></span>
                    <ul class="mb-0 list-unstyled w-100">
                        <li><a href="/" >Home</a></li>                                    
                        <li><a href="/AboutUs">About Us</a></li>
                        <li><a href="/Course">Course</a></li>
                        <li><a href="/Facilities">Facilities</a></li>
                        <li><a href="/Gallery">Gallery</a></li> 
                        <li class="menu-item-has-children">
                            <a href="javascript:void(0);" title="">Mandate</a>
                            <ul class="mb-0 list-unstyled">
                                <li><a href="/assets/images/pdf/NCTE.pdf" target="_blank">NCTE Approval</a></li>
                                <li><a href="/assets/images/pdf/affliation-order.pdf" target="_blank">TNTEU Affiliation</a></li>
                                <li><a href="/assets/images/pdf/bed-syllabus.pdf" target="_blank">B.Ed. Syllabus</a></li>
                                <li><a href="/assets/images/pdf/application.pdf" target="_blank">B.Ed. Admission Norms</a></li> 
                            </ul>
                        </li>
                        <li><a href="/ContactUs">Contact Us</a></li>
                    </ul>
                </div>
            </div>

  </>
);
}

export default Header;