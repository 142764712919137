import React from 'react';

function  Footer () {
  return (
  <>
  
  <section class="copyright">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="footer-social">
            <ul>
              <li><a href="https://www.facebook.com/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
              <li><a href="https://www.instagram.com/" target="_blank"><i class="fab fa-instagram" aria-hidden="true"></i></a></li>
              <li><a href="https://www.linkedin.com/" target="_blank"><i class="fab fa-linkedin" aria-hidden="true"></i></a></li>
              <li><a href="https://www.youtube.com/" target="_blank"><i class="fab fa-youtube" aria-hidden="true"></i></a></li>
            </ul>
          </div>
        <div class="clearfix"></div>
          <p>Copyright © St. Paul’s College of Education </p>
        </div>
        <div class="col-md-4">
          <img src="assets/images/logo.png"/>
        </div>
        <div class="col-md-4">
          <a href="/Gallery">Gallery</a>
          <a href="/Facilities">Facilities</a>
          <a href="/Course">Course</a>
          <a href="/AboutUs">About Us</a>
        </div>
      </div>
    </div>
  </section>
  </>
);
}

export default Footer;