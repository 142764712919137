
import Footer from '../../components/Footer';
import Header from '../../components/Header';   
import Banner from '../Home/Banner';   

import React from 'react';

function  Home () {
  return (
  <>
 <main> 
    <Header />  
    <Banner/> 

    <section>
                <div class="w-100 pt-150 white-layer opc95 pb-100 position-relative">
                    <div class="container">
                        <div class="sec-title sec-title-with-btns d-flex flex-wrap align-items-center justify-content-between w-100">
                            <div class="sec-title-inner">
                                <span class="position-relative thm-clr sub-shap v2 thm-shp d-block">Our Facilities</span>
                                <h2 class="mb-0">Ultimate goal of our St.Paul’s </h2>
                                <p class="mb-0">Adipisicing elit sed dole there eiusmod tempor incididub labore dolore magna aliqua denim ads minim veniam quis nostrud.</p>
                            </div>
                            <div class="sec-title-btns d-inline-flex flex-wrap align-items-center">
                               <a class="thm-btn v2 thm-bg brd-rd5 d-inline-block position-relative overflow-hidden" href="/Facilities">Learn More</a>
                            </div>
                        </div> 
                        <div class="serv-wrap2 res-row position-relative w-100">
                            <div class="row mrg30">
                                <div class="col-md-6 col-sm-6 col-lg-4">
                                    <div class="serv-box2 v2 position-relative w-100">
                                        <div class="serv-img2 brd-rd10 position-relative overflow-hidden w-100">
                                            <img class="img-fluid w-100" src="../assets/images/lib.jpg" alt="Services Image 1"/> 
                                        </div>
                                        <div class="serv-info2 w-100 position-absolute"><h3 class="mb-0"><a href="/Facilities">Library</a></h3></div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-lg-4">
                                    <div class="serv-box2 v2 position-relative w-100">
                                        <div class="serv-img2 brd-rd10 position-relative overflow-hidden w-100">
                                            <img class="img-fluid w-100" src="../assets/images/computer.jpg" alt="Services Image 2"/> 
                                        </div>
                                        <div class="serv-info2 w-100 position-absolute"><h3 class="mb-0"><a href="/Facilities">Computer Laboratory</a></h3></div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-lg-4">
                                    <div class="serv-box2 v2 position-relative w-100">
                                        <div class="serv-img2 brd-rd10 position-relative overflow-hidden w-100">
                                            <img class="img-fluid w-100" src="../assets/images/physical.jpg" alt="Services Image 3"/> 
                                        </div>
                                        <div class="serv-info2 w-100 position-absolute"><h3 class="mb-0"><a href="/Facilities">Psychology Laboratory</a></h3></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>  

            <section>
                <div class="w-100 pt-100 gray-layer opc95 pb-100 position-relative"> 
                    <div class="container">
                        <div class="about-wrap2 position-relative w-100">
                            <div class="row mrg30">
                                <div class="col-md-12 col-sm-12 col-lg-6 order-lg-1">
                                    <div class="about-gal w-100">
                                        <div class="row align-items-end mrg20">
                                            <div class="col-md-6 col-sm-6 col-lg-6">
                                                <div class="about-gal-img brd-rd10 overflow-hidden position-relative w-100">
                                                    <a href="../assets/images/clg5.png" data-fancybox="gallery"><img class="img-fluid w-100" src="../assets/images/clg5.png" alt="About Gallery Image 1"/></a>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-lg-6">
                                                <div class="about-gal-img brd-rd10 overflow-hidden position-relative w-100">
                                                    <a href="../assets/images/clg2.png" data-fancybox="gallery"><img class="img-fluid w-100" src="../assets/images/clg2.png" alt="About Gallery Image 2"/></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mrg20">
                                            <div class="col-md-6 col-sm-6 col-lg-6">
                                                <div class="about-gal-img brd-rd10 brd-rd10 overflow-hidden position-relative w-100">
                                                    <a href="../assets/images/clg1.png" data-fancybox="gallery"><img class="img-fluid w-100" src="../assets/images/clg1.png" alt="About Gallery Image 3"/></a>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-lg-6">
                                                <div class="about-gal-img brd-rd10 overflow-hidden position-relative w-100"> 
                                                <a href="../assets/images/clg4.png" data-fancybox="gallery"><img class="img-fluid w-100" src="../assets/images/clg4.png" alt="About Gallery Image 3"/></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-lg-6">
                                    <div class="about-desc w-100">
                                        <div class="sec-title-inner">
                                            <span class="position-relative thm-clr sub-shap v2 thm-shp d-block">About Us</span>
                                            <h2 class="mb-0">St. Paul’s College of Education</h2>
                                            <p>St. Paul’s College of Education at Gobichettipalayam, Erode District, Tamil Nadu is a Teacher Education College was established for providing the best education both theoretically and practically in order to produce the best future teachers.</p>
                                            <p>The St. Paul’s College of Education, Erode, is a self-financing college founded during the year 2006. This college is established by the St. Paul’s Educational and Charitable Trust, Erode.</p>
                                            {/* <p>The college was recognized by the National Council for Teacher Education, Bangalore with the F.SRO/NCTE/ B.Ed./APSO4846/2006-2007/9716.  The St. Paul’s College of Education, Erode, is also affiliated to Tamil Nadu Teachers Education University, Chennai, vide F.No.TNTEU/R/Cont.Affln. /2022/04110 dt. 13.10.2022. The college is a co-educational college of education and it offers two years B.Ed. programme. </p>  */}
                                            <a class="thm-btn v2 thm-bg brd-rd5 d-inline-block position-relative overflow-hidden" href="/AboutUs">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </section>

            <section>
                <div class="w-100 pt-100 white-layer opc97 pb-100 position-relative"> 
                    <div class="container">
                        <div class="sec-title v2 text-center w-100">
                            <div class="sec-title-inner d-inline-block">
                                <h2 class="mb-0 text-color3">Our Features</h2>
                                <p class="mb-0">To emerge as an Institute of Excellence in Teacher Education by evolving the Future Teachers with Learning, Teaching, and Research Skills through celebrating Tradition-cum-Heritage along with Modern Values</p>
                            </div>
                        </div> 
                        <div class="news-wrap2 res-row position-relative w-100">
                            <div class="row mrg30">
                                <div class="col-md-3 col-sm-6 col-lg-3">
                                    <div class="news-box2 brd-rd10 position-relative overflow-hidden w-100">
                                        <div class="news-img2 position-relative overflow-hidden w-100">
                                            <a href="#"><img class="img-fluid w-100" src="../assets/images/clg3.png" title="News Image 1"/></a>
                                        </div>
                                        <div class="news-info2 brd-rd10 bg-color6 position-relative w-100">
                                            <h3 class="mb-0"><a href="#">Choice Based Credit System</a></h3>
                                            <p>A Student Teacher is expected to earn the credits assigned to successfully complete both Theory and Practicum components of the programme.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-6 col-lg-3">
                                    <div class="news-box2 brd-rd10 position-relative overflow-hidden w-100">
                                        <div class="news-img2 position-relative overflow-hidden w-100">
                                            <a href="#"><img class="img-fluid w-100" src="../assets/images/clg2.png" title="News Image 2"/></a>
                                        </div>
                                        <div class="news-info2 brd-rd10 bg-color6 position-relative w-100">
                                            <h3 class="mb-0"><a href="#">Facilities</a></h3>
                                            <p>St. Paul’s College of Education provide the best possible facilities to enable our students to benefit from a modern learning environment.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-6 col-lg-3">
                                    <div class="news-box2 brd-rd10 position-relative overflow-hidden w-100">
                                        <div class="news-img2 position-relative overflow-hidden w-100">
                                            <a href="#"><img class="img-fluid w-100" src="../assets/images/clg1.png" title="News Image 3"/></a>
                                        </div>
                                        <div class="news-info2 brd-rd10 bg-color6 position-relative w-100">
                                            <h3 class="mb-0"><a href="#">Attendance and Leave</a></h3>
                                            <p>Students are expected to attend class regularly. Leave will be granted only under very rare and exceptional circumstances.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-6 col-lg-3">
                                    <div class="news-box2 brd-rd10 position-relative overflow-hidden w-100">
                                        <div class="news-img2 position-relative overflow-hidden w-100">
                                            <a href="#"><img class="img-fluid w-100" src="../assets/images/clg4.png" title="News Image 3"/></a>
                                        </div>
                                        <div class="news-info2 brd-rd10 bg-color6 position-relative w-100">
                                            <h3 class="mb-0"><a href="#">Reasearch and Development</a></h3>
                                            <p>The Research and development Center aim to create, a research culture for developing and promoting scientific temper and research.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div class="view-more mt-20 d-inline-block text-center w-100">
                            <p class="mb-0">Interested in reading? Explore <a class="simple-link thm-clr d-inline-block" href="/Facilities">The More Features</a> section.</p>
                        </div> 
                    </div>
                </div>
            </section>
            

            <div class="foundationspap our-product">
                <div class="container">
                    <div class="row"> 
                        <div class="col-md-12">
                            <h3>Why Choose St. Paul’s</h3>
                            <ul id="counter">
                                <li>
                                    <div class="images">
                                        <img src="assets/images/impect-1.png" alt=""/>
                                    </div>
                                    <strong>
                                        <div class="counter-value" data-count="75">2500+</div>
                                    </strong> 
                                    <div class="clearfix"></div>
                                    <p>Students</p>
                                </li>
                                <li>
                                    <div class="images">
                                        <img src="assets/images/impect-2.png" alt=""/>
                                    </div>
                                    <strong>
                                        <div class="counter-value" data-count="15">150+</div>
                                    </strong>
                                    <div class="clearfix"></div>
                                    <p>Faculties</p>
                                </li>
                                <li>
                                    <div class="images">
                                        <img src="assets/images/impect-3.png" alt=""/>
                                    </div>
                                    <strong>
                                        <div class="counter-value" data-count="25">25+</div>
                                    </strong>
                                    <div class="clearfix"></div>
                                    <p>Courses</p>
                                </li>
                                <li>
                                    <div class="images">
                                        <img src="assets/images/impect-4.png" alt=""/>
                                    </div>
                                    <strong>
                                        <div class="counter-value" data-count="142">142+</div>
                                    </strong>
                                    <div class="clearfix"></div>
                                    <p>Departments</p>
                                </li>


                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            {/* <section>
                <div class="w-100 blue-layer opc1 pb-120 position-relative">
                    <div class="container">
                        <div class="quote-facts-wrap position-relative w-100">
                            <div class="row mrg30 align-items-end">
                                <div class="col-md-8 col-sm-12 col-lg-8">
                                    <div class="quote-box-wrap position-relative w-100">
                                        <img class="img-fluid brd-rd5 overlap65" src="assets/images/resources/quote-img.jpg" alt="Quote Image"/>
                                        <div class="quote-box position-absolute w-100">
                                            <div class="quote-box-inner thm-bg w-100">
                                               <p class="mb-0">Lorem ipsum dolor sit met, consectetur adip isicing elit sed dousm od temp or inciidunt ut labore aliqua.</p>
                                            </div>
                                            <div class="quote-box-info position-relative overflow-hidden thm-bg brd-rd5 w-100">
                                                <h3 class="mb-0">Rosalina D. Venx</h3>
                                                <span class="d-block">Founder, Coxer Co.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12 col-lg-4">
                                    <div class="facts-wrap position-relative w-100">
                                        <div class="fact-box position-relative d-flex flex-wrap w-100">
                                            <div class="fact-images">
                                                <img src="assets/images/impect-1.png" alt=""/>
                                            </div>
                                            <div class="fact-box-inner">
                                                <span class="scndry-clr d-block"><i class="counter">475+</i></span>
                                                <p class="mb-0">Students Enrolled</p>
                                            </div>
                                        </div>
                                        <div class="fact-box position-relative d-flex flex-wrap w-100">
                                            <div class="fact-images">
                                                <img src="assets/images/impect-1.png" alt=""/>
                                            </div>
                                            <div class="fact-box-inner">
                                                <span class="scndry-clr d-block"><i class="counter">25+</i></span>
                                                <p class="mb-0">Best Awards Won</p>
                                            </div>
                                        </div>
                                        <div class="fact-box position-relative d-flex flex-wrap w-100">
                                            <div class="fact-images">
                                                <img src="assets/images/impect-1.png" alt=""/>
                                            </div>
                                            <div class="fact-box-inner">
                                                <span class="scndry-clr d-block"><i class="counter">142+</i></span>
                                               
                                                <p class="mb-0">Classes Completed</p>
                                            </div>
                                        </div>
                                        <div class="fact-box position-relative d-flex flex-wrap w-100">
                                            <div class="fact-images">
                                                <img src="assets/images/impect-1.png" alt=""/>
                                            </div>
                                            <div class="fact-box-inner">
                                                <span class="scndry-clr d-block"><i class="counter">20+</i></span>
                                               
                                                <p class="mb-0">Our Total Courses</p>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </section> */}


            {/* <section>
                <div class="w-100 position-relative">
                    <div class="special-wrap res-row overflow-hidden position-relative w-100">
                        <div class="row mrg">
                            <div class="col-md-6 col-sm-6 col-lg-4">
                                <div class="special-box v2 text-center z1 scndry-bg grad-bg2 d-flex position-relative flex-wrap w-100">
                                    <i class="fas fa-trophy d-inline-block"></i>
                                    <div class="special-box-inner">
                                        <h4 class="mb-0">Competition Focused Trainers</h4>
                                        <p class="mb-0">Nostrud exerctation to ullamco</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4">
                                <div class="special-box v2 text-center z1 scndry-bg grad-bg2 d-flex position-relative flex-wrap w-100">
                                    <i class="fas fa-medal d-inline-block"></i>
                                    <div class="special-box-inner">
                                        <h4 class="mb-0">Experts Riding Training </h4>
                                        <p class="mb-0">Nostrud exerctation to ullamco</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4">
                                <div class="special-box v2 text-center z1 scndry-bg grad-bg2 d-flex position-relative flex-wrap w-100">
                                    <i class="fas fa-award d-inline-block"></i>
                                    <div class="special-box-inner">
                                        <h4 class="mb-0">Top Breed Horses Selection</h4>
                                        <p class="mb-0">Nostrud exerctation to ullamco</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </section> */}


            <section>
            <div class="w-100 pt-100 white-layer opc97 pb-100 position-relative"> 
                    <div class="container">
                        <div class="sec-title v2 text-center w-100">
                            <div class="sec-title-inner d-inline-block">
                                <h2 class="mb-0 text-color3">Our Gallery</h2>
                                <p class="mb-0 position-relative  v2 thm-shp d-inline-block">Here is what you can expect from a house cleaning from a Handy professional. Download the app to share further cleaning details and instructions!</p>
                            </div>
                        </div> 
                    <div class="container">
                        <div class="gallery-wrap v3 text-center position-relative w-100">
                            <div class="row mrg30"> 
                                <div class="col-md-3 col-sm-12 col-lg-3">
                                    <div class="gallery-box v3 brd-rd10 position-relative overflow-hidden w-100">
                                        <img class="img-fluid w-100" src="assets/images/gallery/1.jpg" alt="Gallery Image 3"/>
                                        <div class="gallery-info position-absolute">
                                            
                                            <a class="d-inline-block" href="assets/images/gallery/1-1.jpg" data-fancybox="gallery"><i class="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-12 col-lg-3">
                                    <div class="gallery-box v3 brd-rd10 position-relative overflow-hidden w-100">
                                        <img class="img-fluid w-100" src="assets/images/gallery/2.jpg" alt="Gallery Image 4"/>
                                        <div class="gallery-info position-absolute">
                                            
                                            <a class="d-inline-block" href="assets/images/gallery/2-1.jpg" data-fancybox="gallery"><i class="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-12 col-lg-3">
                                    <div class="gallery-box v3 brd-rd10 position-relative overflow-hidden w-100">
                                        <img class="img-fluid w-100" src="assets/images/gallery/5.jpg" alt="Gallery Image 5"/>
                                        <div class="gallery-info position-absolute">
                                            
                                            <a class="d-inline-block" href="assets/images/gallery/5-1.jpg" data-fancybox="gallery"><i class="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-12 col-lg-3">
                                    <div class="gallery-box v3 brd-rd10 position-relative overflow-hidden w-100">
                                        <img class="img-fluid w-100" src="assets/images/gallery/8.jpg" alt="Gallery Image 3"/>
                                        <div class="gallery-info position-absolute">
                                            
                                            <a class="d-inline-block" href="assets/images/gallery/8-1.jpg" data-fancybox="gallery"><i class="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-12 col-lg-3">
                                    <div class="gallery-box v3 brd-rd10 position-relative overflow-hidden w-100">
                                        <img class="img-fluid w-100" src="assets/images/gallery/4.jpg" alt="Gallery Image 4"/>
                                        <div class="gallery-info position-absolute">
                                            
                                            <a class="d-inline-block" href="assets/images/gallery/4-1.jpg" data-fancybox="gallery"><i class="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-12 col-lg-3">
                                    <div class="gallery-box v3 brd-rd10 position-relative overflow-hidden w-100">
                                        <img class="img-fluid w-100" src="assets/images/gallery/6.jpg" alt="Gallery Image 5"/>
                                        <div class="gallery-info position-absolute">
                                            
                                            <a class="d-inline-block" href="assets/images/gallery/6-1.jpg" data-fancybox="gallery"><i class="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-12 col-lg-3">
                                    <div class="gallery-box v3 brd-rd10 position-relative overflow-hidden w-100">
                                        <img class="img-fluid w-100" src="assets/images/gallery/7.jpg" alt="Gallery Image 3"/>
                                        <div class="gallery-info position-absolute">
                                            
                                            <a class="d-inline-block" href="assets/images/gallery/7-1.jpg" data-fancybox="gallery"><i class="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-12 col-lg-3">
                                    <div class="gallery-box v3 brd-rd10 position-relative overflow-hidden w-100">
                                        <img class="img-fluid w-100" src="assets/images/gallery/3.jpg" alt="Gallery Image 4"/>
                                        <div class="gallery-info position-absolute">
                                            
                                            <a class="d-inline-block" href="assets/images/gallery/3-1.jpg" data-fancybox="gallery"><i class="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                                 
                            </div>
                        </div> 
                    </div>
                </div>
                </div>
            </section>


            <section class="our-product">
    <div class="container">
        <div class="row">

            <div class="col-md-7">
                <div class="sec-2-contant">
                    <h4>The Truly Global College</h4>
                    <h2>Contact Us to schedule a call with an Admission Officer</h2>
                    <p>For more information or for questions,<br/>
                    <strong>stpaulsb.edcollege@gmail.com </strong> <br/>
                    <strong>+91-98427 05888.</strong>
                    </p>
                    <a class="thm-btn v2 thm-bg brd-rd5 d-inline-block position-relative overflow-hidden" href="/Contact">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section>

    

    <Footer/>
    </main>
        
  </> 
  ); 
  } 
  export default Home;