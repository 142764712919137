import Header from '../../components/Header';  
import Footer from '../../components/Footer'; 
function AboutUs() { 
	return( 
	<>  
    
    <Header />  

    <main> 
        <section class="innerslider no-shadow"> 
            <div class="container">
                <div class="row">
                    <div class="col-md-12"> 	
                        <div class="contants">
                            <h1>About Us</h1>
                            <div class="page-title-inner"> 
                                <ol class="breadcrumb mb-0 justify-content-center">
                                    <li class="breadcrumb-item"><a href="/" title="">Home</a></li>
                                    <li class="breadcrumb-item active">About Us</li>
                                </ol>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </section>  
        <section>
                <div class="w-100 pt-100 opc95 pb-100 position-relative"> 
                    <div class="container">
                        <div class="about-wrap2 position-relative w-100">
                            <div class="row mrg30">
                                <div class="col-md-12 col-sm-12 col-lg-6 order-lg-1">
                                    <div class="about-gal w-100">
                                        <div class="row align-items-end mrg20">
                                            <div class="col-md-6 col-sm-6 col-lg-6">
                                                <div class="about-gal-img brd-rd10 overflow-hidden position-relative w-100">
                                                    <a href="../assets/images/clg1.png" data-fancybox="gallery"><img class="img-fluid w-100" src="../assets/images/clg1.png" alt="About Gallery Image 1"/></a>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-lg-6">
                                                <div class="about-gal-img brd-rd10 overflow-hidden position-relative w-100">
                                                    <a href="../assets/images/clg1.png" data-fancybox="gallery"><img class="img-fluid w-100" src="../assets/images/clg2.png" alt="About Gallery Image 2"/></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mrg20">
                                            <div class="col-md-6 col-sm-6 col-lg-6">
                                                <div class="about-gal-img brd-rd10 brd-rd10 overflow-hidden position-relative w-100">
                                                    <a href="../assets/images/clg1.png" data-fancybox="gallery"><img class="img-fluid w-100" src="../assets/images/clg3.png" alt="About Gallery Image 3"/></a>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-lg-6">
                                                <div class="about-gal-img brd-rd10 overflow-hidden position-relative w-100"> 
                                                    <img class="img-fluid w-100" src="../assets/images/clg4.png" alt="About Gallery Image 4"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-lg-6">
                                    <div class="about-desc w-100">
                                        <div class="sec-title-inner">
                                            <span class="position-relative thm-clr sub-shap v2 thm-shp d-block">About Us</span>
                                            <h2 class="mb-0">St. Paul’s College of Education</h2>
                                            <p>St. Paul’s College of Education at Gobichettipalayam, Erode District, Tamil Nadu is a Teacher Education College was established for providing the best education both theoretically and practically in order to produce the best future teachers.</p>
                                            <p>The St. Paul’s College of Education, Erode, is a self-financing college founded during the year 2006. This college is established by the St. Paul’s Educational and Charitable Trust, Erode.</p>
                                            <p>The college was recognized by the National Council for Teacher Education, Bangalore with the F.SRO/NCTE/ B.Ed./APSO4846/2006-2007/9716.  The St. Paul’s College of Education, Erode, is also affiliated to Tamil Nadu Teachers Education University, Chennai, vide F.No.TNTEU/R/Cont.Affln. /2022/04110 dt. 13.10.2022. The college is a co-educational college of education and it offers two years B.Ed. programme. </p> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </section>
            <section class="founder-note gray-layer ">
                <div class="container">
                    <div class="row">
                    <div class="vison-box">
                        <div class="vision-content">
                            <h4>Our Motto </h4>
                            <p>Our Motto is to give our students the best education both theoretical and practical wise possible.</p>
                            <p>So that when they pass out of our institution at the end of the academic year, they would be able to pass the knowledge to the students they teach in future. </p>
                        </div>
                        <div class="images2">
                            <img src="assets/images/vission.webp" alt="" />
                        </div>
                    </div>
                    <div class="mission-box vison-box">
                        <div class="images2">
                            <img src="assets/images/mission.jpeg" alt="" />
                        </div>
                        <div class="vision-content">
                            <h4>Our Objectives </h4>
                            <p>To enhance skills, values and knowledge in the teacher students through integral education of body, mind and soul. </p>
                            <p>To equip the teacher students to meet the ever-changing demands of the world by providing creative and innovative educational experience. </p>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <div class="foundationspap our-product">
                <div class="container">
                    <div class="row"> 
                        <div class="col-md-12">
                            <h3>Why Choose St. Paul’s</h3>
                            <ul id="counter">
                                <li>
                                    <div class="images">
                                        <img src="assets/images/impect-1.png" alt=""/>
                                    </div>
                                    <strong>
                                        <div class="counter-value" data-count="75">2500+</div>
                                    </strong> 
                                    <div class="clearfix"></div>
                                    <p>Students</p>
                                </li>
                                <li>
                                    <div class="images">
                                        <img src="assets/images/impect-2.png" alt=""/>
                                    </div>
                                    <strong>
                                        <div class="counter-value" data-count="15">150+</div>
                                    </strong>
                                    <div class="clearfix"></div>
                                    <p>Faculties</p>
                                </li>
                                <li>
                                    <div class="images">
                                        <img src="assets/images/impect-3.png" alt=""/>
                                    </div>
                                    <strong>
                                        <div class="counter-value" data-count="25">25+</div>
                                    </strong>
                                    <div class="clearfix"></div>
                                    <p>Courses</p>
                                </li>
                                <li>
                                    <div class="images">
                                        <img src="assets/images/impect-4.png" alt=""/>
                                    </div>
                                    <strong>
                                        <div class="counter-value" data-count="142">142+</div>
                                    </strong>
                                    <div class="clearfix"></div>
                                    <p>Departments</p>
                                </li>


                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <section class="founder-note campuscss addmissionpage founder-note-1">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="founder-note-in">
                                <div class="comment more"> 
                                    <h4>Salient Features of the College</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="founder-note-in">
                                <div class="comment more">
                                    <ul class="about-lis">
                                        <li>Imparting standard education through Good Teachers </li>
                                        <li>Placement Cell Student Welfare Extracurricular & Grievance Committee, Counselling Cell </li>
                                        <li>Internal Quality Assurance Committee, Environment Club, Red Ribbon Club, Youth Red Cross, intership Committee </li>
                                        
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="founder-note-in">
                                <div class="comment more">
                                    <ul class="about-lis">
                                        <li>Continuous achievements in National and State level sports </li>
                                        <li>Campus is well equipped with sophisticated Class Room, Science Lab, Language Lab, Smart Class, Library etc., </li>
                                        <li>College provides safe transport facilities </li>
                                        <li>Spacious and well-ventilated class room. </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
            
    </main>
   <Footer/>

  </> 
  ); 
  } 
  export default AboutUs;