import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function Course() { 
	return( 
	<>  
    
    <Header /> 
    <main> 
        <section class="innerslider no-shadow"> 
            <div class="container">
                <div class="row">
                    <div class="col-md-12"> 	
                        <div class="contants">
                            <h1>Course</h1>
                            <div class="page-title-inner"> 
                                <ol class="breadcrumb mb-0 justify-content-center">
                                    <li class="breadcrumb-item"><a href="/" title="">Home</a></li>
                                    <li class="breadcrumb-item active">Course</li>
                                </ol>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </section> 

        <section class="section-course">
            <div class="container">
                <div class="row">
                    <div class="vison-box">
                        <div class="vision-content">
                            <h4>B.Ed. Programme</h4>
                            <p>B.Ed. programme is a professional course that prepares teachers for upper primary (Classes VI-VIII), secondary level (classes IX-X) and senior secondary level (classes XI-XII). </p>
                            <p>The B.Ed. programme shall be of a duration of two academic years which can be completed in a maximum of three years from the date of admission to the programme.</p>
                            <p>The programme consists of three broad curricular areas: Perspectives in Education, Curriculum and Pedagogic Studies, and Engagement with the Field and School Internship.</p>
                        </div>
                        <div class="images2">
                            <img src="assets/images/clg1.png" alt="" />
                        </div>
                    </div> 
                    <div class="vision-content mt-50">
                            <h4>Programme Content and Details</h4>
                            <p>The programme content will be as per the Tamil Nadu Teachers Education University. </p>
                            <p>The programme will consist of theory and practicum component. For further details, students are asked to refer the university website <a href="www.tnteu.ac.in" target='_blank'>www.tnteu.ac.in.</a></p>
                        </div>
                </div>
            </div>
        </section>
        <section class="section-course-list founder-note-2">
            <div class="container">
                <div class="row">
                    <div class="course-inner-para">
                        <h5>Subjects offered:</h5>
                        <p>The College offers B.Ed., course for the following nine subjects.</p>
                        <span>
                            <i class="fa fa-check-circle bed-color"></i>Tamil Education</span>
                        <span>
                            <i class="fa fa-check-circle bed-color"></i>English Education</span>
                        <span>
                            <i class="fa fa-check-circle bed-color"></i>Mathematics Education</span>
                        <span>
                            <i class="fa fa-check-circle bed-color"></i>Physical Science Education</span>
                        <span>
                            <i class="fa fa-check-circle bed-color"></i>Biological Science Education</span>
                        <span>
                            <i class="fa fa-check-circle bed-color"></i>History Education</span>
                        <span>
                            <i class="fa fa-check-circle bed-color"></i>Commerce Education</span>
                        <span>
                            <i class="fa fa-check-circle bed-color"></i>Computer Science Education</span>
                        <span>
                            <i class="fa fa-check-circle bed-color"></i>Economics Education</span>
                        {/* <span>
                            <i class="fa fa-check-circle bed-color"></i>Social Science </span> */}
                    </div>
                    
                    </div>
                </div>
        </section>
        <section class="founder-note founder-note-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="founder-note-in">
                            <div class="comment more"> 
                                <h4>Eligibility for Admission</h4>
                                <p>B.Ed. admissions are made strictly following the guidelines of the Govt. of Tamil Nadu. </p>
                                <p>Both male and female candidates with the following marks in the UG degree are eligible for admission to the programme. For Commerce and Economics candidates, a pass in PG Degree is vital.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-bordered course-table">
                                <thead align="center" class="bg-dark text-white">
                                    <tr>
                                        <th>SI. No. </th>
                                        <th>Category</th> 
                                        <th>% for Eligibility</th> 
                                    </tr>
                                </thead>
                                <tbody align="center">
                                    <tr>
                                        <td>1.</td>
                                        <td>OC</td>
                                        <td>50 </td> 
                                    </tr>
                                    <tr>
                                        <td>2.</td>
                                        <td>BC	</td>
                                        <td>45 </td> 
                                    </tr>
                                    <tr>
                                        <td>3.</td>
                                        <td>MBC/DNC</td>
                                        <td>43</td> 
                                    </tr>
                                    <tr>
                                        <td>4.</td>
                                        <td>SC</td>
                                        <td>40</td> 
                                    </tr>
                                    <tr>
                                        <td>5.</td>
                                        <td>Physically/Visually Challenged</td>
                                        <td>Minimum Pass</td> 
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
        <section class="founder-note founder-note-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="founder-note-in">
                            <div class="comment more"> 
                                <h4>Procedure for Admission</h4>
                                <p>The completed application form for admission to B.Ed. programme shall be sent to the Principal. After proper screening and evaluation of marks, the candidates are provisionally selected for admission. </p>
                                <p>The following original certificates with 3 sets of Xerox copies should be produced by the candidates at the time of admission. Incomplete applications will summarily be rejected.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="founder-note-in">
                            <div class="comment more">
                                <ul class="about-lis about-lis-1">
                                    <li>Transfer Certificate</li>
                                    <li>Conduct Certificate</li>
                                    <li>SSLC Mark Sheet</li>
                                    <li>HSC Mark Sheet</li>
                                    <li>UG Degree Mark Sheet (PG for Commerce& Economics)</li>
                                    <li>UG Degree/Provisional Certificate (PG for Commerce& Economics)</li>
                                    <li>Community Certificate</li>
                                    <li>Special Certificate, if any</li>
                                    <li>Passport Size Photo</li>
                                </ul>
                                <p>Once admitted, you may experience and enjoy an educative and informative atmosphere in our college.</p>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </section>

        <section class="our-product mt-40">
            <div class="container">
                <div class="row">
                    <div class="col-md-7">
                        <div class="sec-2-contant">
                            <h4>The Truly Global College</h4>
                            <h2>Contact Us to schedule a call with an Admission Officer</h2>
                            <p>For more information or for questions,<br/>
                            <strong>stpaulsb.edcollege@gmail.com </strong> <br/>
                            <strong>+91-98427 05888.</strong>
                            </p>
                            <a class="thm-btn v2 thm-bg brd-rd5 d-inline-block position-relative overflow-hidden" href="/Contact">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
         
    </main>
    
    
    <Footer/>

  </> 
  ); 
  } 
  export default Course;