import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function ContactUs() { 
	return( 
	<>  
    
      <Header /> 
      <section class="innerslider no-shadow"> 
            <div class="container">
                <div class="row">
                    <div class="col-md-12"> 	
                        <div class="contants">
                            <h1>Contact Us</h1>
                            <div class="page-title-inner"> 
                                <ol class="breadcrumb mb-0 justify-content-center">
                                    <li class="breadcrumb-item"><a href="/" title="">Home</a></li>
                                    <li class="breadcrumb-item active">Contact Us</li>
                                </ol>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </section> 
        <section>
                <div class="section-contact">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-7 col-md-8">
                                <div class="section-title text-center mb-50">
                                    <h2 class="title title-1">Let's Contact Us</h2>
                                    <p>Here is what you can expect from a house cleaning from a Handy professional. Download the app
                                        to share further cleaning details and instructions!</p>
                                </div>
                            </div>
                        </div> 

                      <div class="col-lg-12">
                        <div class="contact-and-address">
                          <div class="contact-and-address-content">
                            <div class="row">
                              <div class="col-lg-4 col-md-4">
                                <div class="contact-card">
                                  <div class="icon">
                                    <i class="fa fa-phone"></i>
                                  </div>
                                  <h4>Contact Numbers </h4>
                                  <p><a>04285-228502</a></p> 
                                  <p><a>+91-98427 05888</a></p> 
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4">
                                <div class="contact-card">
                                  <div class="icon">
                                    <i class="far fa-envelope"></i>
                                  </div>
                                  <h4>Email Id</h4> 
                                  <p><a href="mailto:stpaulsb.edcollege@gmail.com">stpaulsb.edcollege@gmail.com</a></p>
                                  <br/>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4">
                                <div class="contact-card">
                                  <div class="icon">
                                    <i class="fas fa-map-marker-alt"></i>
                                  </div>
                                  <h4>Address</h4>
                                  <p>St. Paul’s College of Education<br/>Gobichettipalayam,Erode – 638 476</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        
                        </div>
                      </div>
                        
                    </div>
                </div>
            </section>
            <div class="contact-us-area pt-100 pb-70">
              <div class="container">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="contacts-form">
                      <h3>Leave a message</h3>
                      <form id="contactForm" novalidate="true">
                        <div class="row">
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Your name</label>
                              <input type="text" name="name" id="name" class="form-control" required="" data-error="Please enter your name"/>
                              <div class="help-block with-errors"></div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Your email</label>
                              <input type="email" name="email" id="email" class="form-control" required="" data-error="Please enter your email"/>
                              <div class="help-block with-errors"></div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Your phone</label>
                              <input type="text" name="phone_number" id="phone_number" required="" data-error="Please enter your number" class="form-control"/>
                              <div class="help-block with-errors"></div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Subject</label>
                              <input type="text" name="msg_subject" id="msg_subject" class="form-control" required="" data-error="Please enter your subject"/>
                              <div class="help-block with-errors"></div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label>Your message</label>
                              <textarea name="message" class="form-control" id="message" cols="30" rows="8" required="" data-error="Write your message"></textarea>
                              <div class="help-block with-errors"></div>
                            </div>
                          </div> 
                          <div class="col-lg-12 col-md-12">
                            <button type="submit" class="thm-btn v2 thm-bg brd-rd5 d-inline-block position-relative overflow-hidden" >
                              <span>Send message</span>
                            </button>
                            <div id="msgSubmit" class="h3 text-center hidden"></div>
                            <div class="clearfix"></div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="col-md-6"> 
                    <div id="google_map" class="google_map">
                        <div class="map_container">
                            <div id="map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15641.457118763185!2d77.4383331!3d11.453611!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba93d97aa108e11%3A0xdb67cd2144700dcc!2sSt.%20Pauls%20College%20of%20Education!5e0!3m2!1sen!2sin!4v1699044979032!5m2!1sen!2sin" height="350"></iframe>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      <Footer/>

  </> 
  ); 
  } 
  export default ContactUs;