
function Banner() { 
	return( 
	<>
    
    <section>
                <div class="w-100 position-relative">
                    <div class="feat-wrap2 position-relative w-100">
                        <div class="feat-caro2 w-100">
                            <div class="feat-item2 position-relative d-block w-100">
                                <div class="feat-img position-relative d-block w-100">
                                  <div class="banner-bg-images">
                                    <img src="../assets/images/student-bg.jpg" alt=""/>
                                  </div>
                                </div>
                                <div class="feat-cap2-wrap text-center d-flex flex-wrap justify-content-center position-absolute w-100">
                                    <div class="feat-cap2 d-inline-block w-100"> 
                                        <h2 class="mb-0">An investment in knowledge pays the best interest </h2>
                                        <p>You can have it all Teacher Training education that broadens your mind, the chance to serve your community</p>
                                        
                                        <a class="thm-btn v2 thm-bg brd-rd5 position-relative d-inline-block overflow-hidden" href="#">GET STARTED</a>
                                    </div>
                                </div>
                            </div>
                            <div class="feat-item2 position-relative d-block w-100">
                                <div class="feat-img position-relative d-block w-100">
                                  <div class="banner-bg-images">
                                    <img src="../assets/images/slide-2.jpg" alt=""/>
                                  </div>
                                </div>
                                <div class="feat-cap2-wrap text-center d-flex flex-wrap justify-content-center position-absolute w-100">
                                    <div class="feat-cap2 d-inline-block w-100"> 
                                        <h2 class="mb-0">Our library is stocked with 5700 B.Ed books</h2>
                                        <p>Library is the heart of Educational Institutions. The main aim of library is to provide right documents to right users at right time.</p> 
                                        <a class="thm-btn v2 thm-bg brd-rd5 position-relative d-inline-block overflow-hidden" href="#">GET STARTED</a>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="w-100 position-relative">
                    <div class="container">
                        <div class="special-wrap res-row brd-rd5 overflow-hidden overlap140 overlap-85 w-100 wide-sec">
                            <div class="row mrg">
                                <div class="col-md-3 col-sm-6 col-lg-3">
                                    <div class="special-box z1 scndry-bg grad-bg2 position-relative w-100">
                                        <i class="fas fa-volleyball-ball d-inline-block"></i>
                                        <div class="special-box-inner">
                                            <h4 class="mb-0">Sports Ground</h4>
                                            <p class="mb-0">The sprawling sports ground enables all games like football, basket ball, handball, volley ball, throw ball, cricket.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-6 col-lg-3">
                                    <div class="special-box z1 scndry-bg grad-bg2 position-relative w-100">
                                        <i class="fas fa-bus d-inline-block"></i>
                                        <div class="special-box-inner">
                                            <h4 class="mb-0">Transport Facility</h4>
                                            <p class="mb-0">Our transport facility provides safe conveyance for the students. The facilities are well-organized, safe and secure. </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-6 col-lg-3">
                                    <div class="special-box z1 scndry-bg grad-bg2  position-relative w-100">
                                        <i class="fas fa-chalkboard d-inline-block"></i>
                                        <div class="special-box-inner">
                                            <h4 class="mb-0">Laboratories</h4>
                                            <p class="mb-0">The well equipped and well constructed labs provide an enjoyable learning experience for the students.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-6 col-lg-3">
                                    <div class="special-box z1 scndry-bg grad-bg2 position-relative w-100">
                                        <i class="fas fa-user d-inline-block"></i>
                                        <div class="special-box-inner">
                                            <h4 class="mb-0">Laboratories</h4>
                                            <p class="mb-0">The well equipped and well constructed labs provide an enjoyable learning experience for the students.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>  
        
  </> 
  ); 
  } 
  export default Banner;